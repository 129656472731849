import React from 'react';
import Typical from 'react-typical';
import "./Profile.css"
import ScrollService from '../../../utilities/ScrollService';

function Profile (props) {
  return (
    <div className='profile-container'>
      <div className='profile-parent'>
        <div className='profile-details'>
          <div className='colz'>
            <div className='colz-icon'>
              <a href='https://www.instagram.com/leoreiners/'>
                <i className='fa fa-instagram'></i>
              </a>
              <a href='https://github.com/leoreiners'>
                <i className='fa fa-github-square'></i>
              </a>
              <a href='https://www.linkedin.com/in/leonardoreiners/' >
                <i className='fa fa-linkedin-square'></i>
              </a>
              <a href='https://github.com/leoreiners' style={{ visibility: 'hidden' }}>
                <i className='fa fa-github-square'></i>
              </a>
              <a href='https://github.com/leoreiners' style={{ visibility: 'hidden' }}>
                <i className='fa fa-github-square'></i>
              </a>
            </div>
          </div>
          <div className='profile-details-name'>
            <span className='primary-text'>
              {""}
              Hello, I'm <span className='highlighted-text'>Leonardo</span>
            </span>
          </div>
          <div className='profile-details-role'>
            <span>
              {""}
              <h1>
                <Typical
                  loop={Infinity}
                  steps={[
                    "Fullstack Developer",
                    2000,
                    "Team guy, help first",
                    1000,
                  ]}
                />
              </h1>
              <span className='profile-role-tagline'>
                Love building web applications with front and back end responsibilities.
              </span>
            </span>
          </div>
          <div className='profile-options'>
            <button className='btn primary-btn' onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
              {""}
              Hire me{" "}
            </button>
            <a href='LeoReinersCV.pdf' download='Leonardo Reiners.pdf'>
              <button className='btn highlighted-btn'>Get Resume</button>
            </a>
          </div>
        </div>
        <div className='profile-picture'>
          <div className='profile-picture-background'>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;