// import React, { useState } from "react";
import Typical from "react-typical";
// import axios from "axios";
// import { toast } from "react-toastify";

// import imgBack from "../../../src/images/mailz-removebg-preview.png";
// import load1 from "../../../src/images/XVo6.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../../PortfolioContainer/Home/Footer/Footer";
import "./ContactMe.css";

export default function ContactMe (props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  // const [banner, setBanner] = useState("");
  // const [bool, setBool] = useState(false);

  // const handleName = (e) => {
  //   setName(e.target.value);
  // };
  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const handleMessage = (e) => {
  //   setMessage(e.target.value);
  // };
  // console.log(name);
  // const submitForm = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let data = {
  //       name,
  //       email,
  //       message,
  //     };
  //     setBool(true);
  //     const res = await axios.post("/contact", data);
  //     console.log('2', data)
  //     if (name.length === 0 || email.length === 0 || message.length === 0) {
  //       setBanner(res.data.msg);
  //       toast.error(res.data.msg);
  //       setBool(false);
  //     } else if (res.status === 200) {
  //       setBanner(res.data.msg);
  //       toast.success(res.data.msg);
  //       setBool(false);

  //       setName("");
  //       setEmail("");
  //       setMessage("");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="col">
          <h2 className="title">
            <Typical loop={Infinity} steps={["Get In Touch! ", 1000]} />
          </h2>{" "}
          <a href='https://www.instagram.com/leoreiners/'>
            <i className='fa fa-instagram'></i>
          </a>
          <a href='https://github.com/leoreiners'>
            <i className='fa fa-github-square'></i>
          </a>
          <a href='https://www.linkedin.com/in/leonardoreiners/' >
            <i className='fa fa-linkedin-square'></i>
          </a>
          <a href='https://github.com/leoreiners' style={{ visibility: 'hidden' }}>
            <i className='fa fa-github-square'></i>
          </a>
          <a href='https://github.com/leoreiners' style={{ visibility: 'hidden' }}>
            <i className='fa fa-github-square'></i>
          </a>
        </div>
        <div className="back-form">
          <div className="img-back">
            <h4>You can Contact me through LinkedIn by clicking on the logo above!</h4>
            <br />
            <br />
            <h4>Or if you prefer, you can send me an email at <a href="mailto:developer@leoreiners.com">developer@leoreiners.com</a> and I'll answer ASAP!</h4>
            <br />
            {/* <p>(There was an automatic email sending component here but the host doesn't allow NodeJs backend, if you're interested you can see more <a href="https://github.com/leoreiners" style={{ fontSize: 11 }}>here</a>.)</p> */}
            {/* <img src={imgBack} alt="image not found" /> */}
          </div>
          {/* <form onSubmit={submitForm}>
            <p>{banner}</p>
            <label htmlFor="name">Name</label>
            <input type="text" onChange={handleName} value={name} />

            <label htmlFor="email">Email</label>
            <input type="email" onChange={handleEmail} value={email} />

            <label htmlFor="message">Message</label>
            <textarea type="text" onChange={handleMessage} value={message} />

            <div className="send-btn">
              <button type="submit">
                Send&nbsp;&nbsp;
                <i className="fa fa-paper-plane" />
                {bool ? (
                  <b className="load">
                    <img src={load1} alt="image not responding" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
          </form> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}