import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Best Projects", logoSrc: "projects.svg" },
    { label: "Siemens/AjaxTI", logoSrc: "work-history.svg" },
    { label: "Dilleta", logoSrc: "work-history.svg" },
    { label: "MBR Tech", logoSrc: "work-history.svg" },
    { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    { label: "Education", logoSrc: "education.svg" },
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "JavaScript", amountYears: '5+ Years' },
    { skill: "Python", amountYears: '5+ Years' },
    { skill: "React JS", amountYears: '4+ Years' },
    { skill: "VueJS", amountYears: '4+ Years' },
    { skill: "Jest", amountYears: '3+ Years' },
    { skill: "Node JS", amountYears: '4+ Years' },
    { skill: "Docker", amountYears: '3+ Years' },
    { skill: "MySQL", amountYears: '4+ Years' },
    { skill: "Flutter", amountYears: '2+ Years' },
    { skill: "Typescript", amountYears: '3+ Years' },
  ];

  const projectsDetails = [
    {
      title: "Siemens Brazil Management System",
      duration: { fromDate: "2021", toDate: "2023" },
      description:
        "A modern and fast Management System where more than 1000 workers used it daily to record/check the number of hours worked, PMs and coordinators created and managed tasks in addition to monitoring their teams. With that saving several hours and people daily.",
      subHeading: "Technologies Used: React JS, VueJS, Python, NodeJS, Typescript, Flask, Jest",
    },
    {
      title: "Sebrae-MT Startup Map",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "Developed a website for Sebrae-MT and created a map of startups in the state using the Google API by creating a custom Pin with company logos using Canvas JS, Allowing them to follow and help in the growth of startups in the region.",
      subHeading:
        "Technologies Used: VueJS, Quasar, NodeJS, Jest, Docker, CanvasJS, GoogleMaps API",
    },
    {
      title: "Dilleta Fintech Mobile App",
      duration: { fromDate: "2021", toDate: "2022" },
      description:
        "Fintech Mobile App, built the Front-end and API requests from back-end, recreating a modern and fast design entirely from Figma.",
      subHeading:
        "Technologies Used: Flutter, NodeJS, Typescript, Figma, Docker.",
    },
  ];

  const resumeDetails = [

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
        />
      ))}
    </div>,

    /* Siemens */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"Siemens/AjaxTI"}
          subHeading={"FULLSTACK DEVELOPER"}
          fromDate={"2021"}
          toDate={"2023"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            I had as responsibilities:
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            - Maintenance of legacy systems made in Python, while creating/updating tools with modern python librarys.
          </span>
          <br />
          <span className="resume-description-text">
            - Creation of new interfaces using JavaScript with ReactJS and VueJs alongside
            Quasar.{" "}
          </span>
          <br />
          <span className="resume-description-text">
            - Creating APIs using Python and NodeJs with TypeScript.
          </span>
          <br />
          <span className="resume-description-text">
            - Creation of unit and integration tests using pytest/Jest.
          </span>
          <br />
          <span className="resume-description-text">
            - Lead the Front-end of a team of 3 interns and do pair coding with them to teach/help their tasks.
          </span>
          <br />
        </div>
      </div>
    </div>,

    /* Dilleta */
    <div className="resume-screen-container" key="work-experience2">
      <div className="experience-container">
        <ResumeHeading
          heading={"Dilleta Solutions"}
          subHeading={"Mobile Developer"}
          fromDate={"2021"}
          toDate={"2021"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            I had as responsibilities:
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            - Creating Mobile apps using Flutter, from Fintechs to a variety of creative fun apps.
          </span>
          <br />
          <span className="resume-description-text">
            - Development of REST APIs using Nodejs.{" "}
          </span>
          <br />
          <span className="resume-description-text">
            - Creating/using mobile app designs with Figma.
          </span>
          <br />
          <span className="resume-description-text">
            - Participating on a team hackathon to create on 3 weeks a modern and fun mobile app, where we created an weather app with a pokemon theme that could get any
            city weather for the week and match it with a similar pokemon type.
          </span>
          <br />
        </div>
      </div>
    </div>,

    /* MBR Tech */
    <div className="resume-screen-container" key="work-experience3">
      <div className="experience-container">
        <ResumeHeading
          heading={"MBR Tech"}
          subHeading={"FULLSTACK DEVELOPER"}
          fromDate={"2021"}
          toDate={"Present"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            I had as responsibilities:
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            - Develop modern websites/Websystems for multiple companies.
          </span>
          <br />
          <span className="resume-description-text">
            - Build application interfaces using JavaScript and ReactJS/
            Vue.js.{" "}
          </span>
          <br />
          <span className="resume-description-text">
            - Develop a website for Sebrae-MT and create a map of
            startups in the state using the Google API by creating a
            custom Pin with company logos using Canvas JS.
          </span>
          <br />
          <span className="resume-description-text">
            - Being the main Front-end of the project to create a digital
            bank for an insurance company in Minas Gerais
          </span>
          <br />
        </div>
      </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-years">
            {/* percentage bar for skills, dont like it, but cool to make */}
            {/* <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div> */}
            <h5>{skill.amountYears}</h5>
          </div>
        </div>
      ))}
    </div>,

    // Education
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"University of Sinop, Brazil"}
        subHeading={"Bachelor of System Development"}
        fromDate={"2019"}
        toDate={"2023"}
      />

      <ResumeHeading
        heading={"English Course and MET C1 certification"}
        subHeading={"FISK School"}
        fromDate={"2012"}
        toDate={"2017"}
      />
      <ResumeHeading
        heading={"School"}
        subHeading={"COLÉGIO REGINA PACIS"}
        fromDate={"2004"}
        toDate={"2016"}
      />
    </div>,


  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;